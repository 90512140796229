// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General footer styles */
footer {
    background-color: #282c34;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

footer .left,
footer .right {
    flex: 1 1;
}

footer .center {
    flex: 1 1;
    display: flex;
    justify-content: center;
}

footer .icon {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
}

footer .link {
    color: #fff;
    text-decoration: none;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    footer .container {
        align-items: center;
    }

    footer .left,
    footer .right {
        text-align: center;
        margin: 10px 0;
    }

    footer .center {
        display: none; /* Hide social links */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;;IAEI,SAAO;AACX;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA,oCAAoC;AACpC;IACI;QACI,mBAAmB;IACvB;;IAEA;;QAEI,kBAAkB;QAClB,cAAc;IAClB;;IAEA;QACI,aAAa,EAAE,sBAAsB;IACzC;AACJ","sourcesContent":["/* General footer styles */\nfooter {\n    background-color: #282c34;\n    color: #fff;\n    text-align: center;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    z-index: 100;\n}\n\nfooter .container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 20px;\n}\n\nfooter .left,\nfooter .right {\n    flex: 1;\n}\n\nfooter .center {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n}\n\nfooter .icon {\n    color: #fff;\n    margin: 0 10px;\n    text-decoration: none;\n}\n\nfooter .link {\n    color: #fff;\n    text-decoration: none;\n}\n\n/* Media query for smaller screens */\n@media (max-width: 768px) {\n    footer .container {\n        align-items: center;\n    }\n\n    footer .left,\n    footer .right {\n        text-align: center;\n        margin: 10px 0;\n    }\n\n    footer .center {\n        display: none; /* Hide social links */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
