// src/components/reviewPostsTable.js
import React from 'react';
import { useTable } from 'react-table';
import {Button} from "@mui/material";

const ReviewPostsTable = ({ data, onApprove, onDecline }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Date / Time',
                accessor: 'date', // accessor is the "key" in the data
            },
            {
                Header: 'Wallet Address',
                accessor: 'wallet',
            },
            {
                Header: 'Link',
                accessor: 'link',
                Cell: ({ cell: { value } }) => (
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        View Post
                    </a>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row: { original } }) => (
                    <div>
                        <Button style={{color: 'green'}} onClick={() => onApprove(original.id)}>Approve</Button>
                        <Button style={{color: 'red'}} onClick={() => onDecline(original.id)}>Decline</Button>
                    </div>
                ),
            },
        ],
        [onApprove, onDecline]
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '20px' }}>
            <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ position: 'sticky', top: 0, background: '#d4edda', zIndex: 1 }}>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps()}
                            style={{
                                background: '#d4edda',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                padding: '10px',
                                borderRadius: '10px',
                            }}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                            <td
                                {...cell.getCellProps()}
                                style={{
                                    padding: '10px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </table>
        </div>
    );
};

export default ReviewPostsTable;