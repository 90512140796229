// src/Dashboard.js
import React, {useState} from 'react';
import styled from 'styled-components';
import './dashboard.css';
import {Typography} from "@mui/material";

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Tile = styled.div`
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LargeTile = styled(Tile)`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MultiplierSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  & > div {
    margin: 5px 0;
    padding: 10px;
    border-radius: 4px;
  }
`;


const CopyButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const Dashboard = ({stats, onClick, multipliers, onSubmit}) => {

    const [newLink, setNewLink] = useState('');

    const copyLink = () => {
        navigator.clipboard.writeText(stats.referalLink);
        alert('Invitation link copied to clipboard!');
    };

    return (
        <DashboardContainer className="Dashboard">
            <LargeTile>
                <div><b>WALLET</b></div>
                <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                }}>{stats.wallet}</div>
                <CopyButton style={{marginTop: '5px'}} onClick={onClick}>LOGOUT</CopyButton>
            </LargeTile>
            <Tile>
                <div><b>POINTS</b></div>
                <div>{stats.points} {stats.multiplier > 1 && stats.points > 10 && (<Typography style={{color: 'green'}}>Multiplier rewards: +{Math.round((stats.multiplier-1)*stats.points)} points</Typography>)}
                </div>
            </Tile>
            <Tile>
                <div><b>POSTS</b></div>
                <div>{stats.posts}{stats.pendingPosts > 0 && (<Typography style={{color: 'grey'}}> Pending posts: {stats.pendingPosts}</Typography>)}</div>
            </Tile>
            <LargeTile>
                <div style={{width: '100%'}}>
                    <div><b>SUBMIT POSTS</b></div>
                    <input
                        type="text"
                        value={newLink}
                        onChange={(e) => setNewLink(e.target.value)}
                        placeholder="Submit new link"
                        style={{width: '100%', marginBottom: '10px'}}
                    />
                    <CopyButton disabled={newLink.length === 0} onClick={() => {
                        onSubmit(newLink);
                        setNewLink('');
                    }}>SEND</CopyButton>
                </div>
            </LargeTile>
            <LargeTile>
                <div><b>ELIGIBLE FRIENDS</b></div>
                <div>{stats.invitedFriends}</div>
                <div>{stats.pendingFriends > 0 && (<Typography style={{color: 'blue'}}> Pending friends: {stats.pendingFriends}</Typography>)}</div>                <MultiplierSection>
                    {multipliers.map((level, index) => (
                        <div
                            key={index}
                            className={stats.invitedFriends >= level.friends ? 'active' : ''}
                            style={{
                                backgroundColor: stats.invitedFriends >= level.friends && stats.invitedFriends < level.friends+1 ? '#d4edda' : '',
                                color: stats.invitedFriends >= level.friends ? '#155724' : 'grey',
                            }}
                        >
                            {level.friends}+ Friends ... {level.multiplier}x multiplier
                        </div>
                    ))}
                </MultiplierSection>
                <div>
                    <input type="text" readOnly value={stats.referalLink} style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '10px',
                    }} />
                    <CopyButton onClick={copyLink}>COPY</CopyButton>
                </div>
            </LargeTile>
        </DashboardContainer>
    );
};

export default Dashboard;