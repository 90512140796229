import api from '../api';


const useApi = ()  => {

    const getLogin = async (signature, address, registrar) => {
        try {
            const response = await api.post(
                '/login',
                { signature, address, registrar }
            );
            return response.status === 200;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };
    const getMultipliers = async () => {
        try {
            const response = await api.get(
                '/multipliers'
            );
            console.error(response.data.multipliers)
            return response.data.multipliers
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const submitLink = async (signature, address, link) => {
        try {
            const response = await api.post(
                '/submit',
                { signature, address, link }
            );
            console.log(response)
            return response.status;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const approvePost = async (signature, address, post_id) => {
        try {
            const response = await api.put(
                '/admin/posts/approve',
                { signature, address, post_id }
            );
            console.log(response)
            return response.status;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const rejectPost = async (signature, address, post_id) => {
        try {
            const response = await api.put(
                '/admin/posts/reject',
                { signature, address, post_id }
            );
            console.log(response)
            return response.status;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const getStats = async (signature, address) => {
        try {
            const response = await api.get(
                '/stats',
                {
                    headers:
                        {
                    signature,
                        address }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const getAdminStats = async (signature, address) => {
        try {
            const response = await api.get(
                '/admin/stats',
                {
                    headers:
                        {
                            signature,
                            address }
                }
            );
            console.log(response.data)
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const getPendingPosts = async (signature, address) => {
        try {
            const response = await api.get(
                '/admin/posts/pending',
                {
                    headers:
                        {
                            signature,
                            address
                        }
                }
            );
            console.error(response.data)
            return response.data.pendingPosts;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };



    return { getLogin, getMultipliers, submitLink, getStats, getAdminStats, getPendingPosts, approvePost, rejectPost };
};

export default useApi;
