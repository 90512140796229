import React, { useState } from 'react';
import Modal from 'react-modal';
import './termsAndConditionsPopup.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

Modal.setAppElement('#root');


const TermsAndConditionsPopup = ({isModalOpen, setIsModalOpen, connectWallet}) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleContinue = () => {
        if (isChecked) {
            setIsModalOpen(false);
            connectWallet()
            setIsChecked(false)
        } else {
            alert('Please accept the terms and conditions to continue.');
        }
    };

    return (
        <div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Terms and Conditions"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        padding: '20px',
                    },
                }}
            >
                <h2>Terms and Conditions</h2>
                <div style={{ height: '400px' }}>
                    <iframe
                        src="/DR%20Social%20Media%20Bounty%20Terms%20and%20Conditions%20ENG.pdf"
                        width="100%"
                        height="400px"
                        title="Terms and Conditions"
                    />

        </div>
                <div style={{ marginTop: '20px' }}>
                    <input
                        type="checkbox"
                        id="acceptCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="acceptCheckbox">
                        I have read and accept the terms and conditions
                    </label>
                </div>
                <button className={'copy-button'} onClick={handleContinue} style={{ marginTop: '20px' }} disabled={!isChecked}>
                    Continue
                </button>
            </Modal>
        </div>
    );
};

export default TermsAndConditionsPopup;