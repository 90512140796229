// src/Dashboard.js
import React from 'react';
import styled from 'styled-components';
import './dashboard.css';
import {Typography} from "@mui/material";
import ReviewPostsTable from "./reviewPostsTable";
import { HiOutlineEmojiSad} from "react-icons/hi";

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Tile = styled.div`
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LargeTile = styled(Tile)`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`;


const CopyButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const AdminDashboard = ({stats, onClick, pendingPosts, approvePost, rejectPost}) => {
    return (
        <DashboardContainer className="Dashboard">
            <LargeTile>
                <div ><b>WALLET</b></div>
                <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                }}>{stats.wallet}</div>
                <CopyButton style={{marginTop: '5px'}} onClick={onClick}>LOGOUT</CopyButton>
            </LargeTile>
            <Tile>
                <div><b>PENDING POSTS</b></div>
                <div>{stats.pendingPosts}</div>
            </Tile>
            <Tile>
                <div><b>TOTAL POSTS</b></div>
                <div>{stats.totalPosts}</div>
            </Tile>
            <Tile>
                <div><b>TOTAL POINTS</b></div>
                <div>{stats.totalPoints}
                </div>
            </Tile>
            <Tile>
                <div><b>TOTAL USERS</b></div>
                <div>{stats.totalUsers}</div>
            </Tile>
            <LargeTile>
                <div style={{width: '100%'}}>
                    <div><b>REVIEW POSTS</b></div>
                    { pendingPosts.length > 0 ? (<ReviewPostsTable data={pendingPosts} onApprove={approvePost} onDecline={rejectPost} />) : (
                        <div style={{padding: '5px'}}>
                        <HiOutlineEmojiSad size={48} color={'#007bff'}/>
                        <Typography variant="body1">No pending posts...</Typography>
                        </div>
                    )
                    }
                </div>
            </LargeTile>
            <LargeTile>
                <div><b>LEADERBOARD</b></div>
                <div>coming soon...</div>
            </LargeTile>
        </DashboardContainer>
    );
};

export default AdminDashboard;
