// Notification.js
import React from 'react';
import './notification.css'; // Make sure to create a CSS file for styling

const Notification = ({ message, type }) => {
    if (!message) return null;

    return (
        <div className={`notification ${type}`}>
            {message}
        </div>
    );
};

export default Notification;