import axios from 'axios';


const url = process.env.REACT_APP_API_URL;
const api = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
    },
});

export default api;
