// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* termsAndConditionsPopup.css */
.copy-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.copy-button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .ReactModal__Content {
        width: 90% !important; /* Adjust the width for small screens */
        height: auto !important;
        padding: 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/termsAndConditionsPopup.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;QACI,qBAAqB,EAAE,uCAAuC;QAC9D,uBAAuB;QACvB,wBAAwB;IAC5B;AACJ","sourcesContent":["/* termsAndConditionsPopup.css */\n.copy-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 4px;\n    cursor: pointer;\n}\n.copy-button:disabled {\n    background-color: grey;\n    cursor: not-allowed;\n}\n\n@media (max-width: 768px) {\n    .ReactModal__Content {\n        width: 90% !important; /* Adjust the width for small screens */\n        height: auto !important;\n        padding: 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
