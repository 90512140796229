import React from 'react';
import { FaTwitter, FaTelegram, FaGlobe, FaYoutube, FaReddit, FaBook } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='left'>
                    <p>© Data River 2024</p>
                </div>
                <div className='center'>
                    <a href="https://data-lake.co/" className='icon'>
                        <FaGlobe size={24} />
                    </a>
                    <a href="https://x.com/datalaketoken" className='icon'>
                        <FaTwitter size={24} />
                    </a>
                    <a href="https://t.me/DataLakeCommunity" className='icon'>
                        <FaTelegram size={24} />
                    </a>
                    <a href="https://youtube.com/@datalakeco" className='icon'>
                        <FaYoutube size={24} />
                    </a>
                    <a href="https://reddit.com/r/DataLake_Token" className='icon'>
                        <FaReddit size={24} />
                    </a>
                    <a href="https://docs.data-lake.co/" className='icon'>
                        <FaBook size={24} />
                    </a>
                </div>
                <div className='right'>
                    <a href="/DR%20Social%20Media%20Bounty%20Terms%20and%20Conditions%20ENG.pdf" className='link'><u>Terms & Conditions</u></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;