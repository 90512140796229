// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Notification.css */
.notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    transition: opacity 0.5s ease-in-out;
}

.notification.error {
    background-color: #e74c3c;
}

.notification.success {
    background-color: #2ecc71;
}

.notification.info {
    background-color: #3498db;
}`, "",{"version":3,"sources":["webpack://./src/components/notification.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,yCAAyC;IACzC,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Notification.css */\n.notification {\n    position: fixed;\n    bottom: 20px;\n    left: 20px;\n    padding: 10px 20px;\n    border-radius: 5px;\n    color: #fff;\n    z-index: 1000;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    opacity: 0.9;\n    transition: opacity 0.5s ease-in-out;\n}\n\n.notification.error {\n    background-color: #e74c3c;\n}\n\n.notification.success {\n    background-color: #2ecc71;\n}\n\n.notification.info {\n    background-color: #3498db;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
